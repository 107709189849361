<template>
  <div class="users">
    <v-card tile>
      <div>
        <v-row class="page_filterTable ma-0 px-1">
          <v-col cols="8">
            <v-text-field
              v-model="searchUsers"
              color="#e0a677"
              label="Имя/E-mail"
              placeholder="Иван/m4@m4r.ru"
            />
          </v-col>
          <v-col cols="2" class="pt-6">
              <v-btn icon @click="openFilters = !openFilters">
                <v-badge
                  v-if="filterUserByRole"
                  color="orange"
                  overlap
                  content="1"
                >
                  <v-icon>mdi-tune</v-icon>
                </v-badge>
                <v-icon v-else>mdi-tune</v-icon>
              </v-btn>
          </v-col>
          <v-col cols="2" class="pt-6">
            <v-btn icon @click="createUser()">
              <v-icon>mdi-account-plus-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card flat tile v-show="openFilters">
            <v-divider />
            <v-row class="page_filterTable ma-0 px-1">
              <v-col cols="12" class="pt-0">
                <v-select
                  color="#e0a677"
                  v-model="filterUserByRole"
                  :items="userRoles"
                  label="Роль сотрудника"
                  clearable
                />
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </div>
      <v-divider />
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Имя</th>
              <th class="text-left">E-mail</th>
              <th class="text-left">Район</th>
              <th class="text-left">Группа</th>
              <th class="text-left">Роль</th>
              <th class="text-left">Норма час</th>
              <th class="text-right">Действия</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.uid" @click="goUser(user)">
              <td>{{ user.displayName }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.location }}</td>
              <td style="min-width: 110px;">
                <span v-if="user.brig">{{ user.brig.displayName }}</span>
                <span v-else>Не указана</span>
              </td>
              <td style="min-width: 100px;">{{ roleText[user.role] }}</td>
              <td style="min-width: 100px;">
                <span v-if="(user.role === 'master' && user.rate === 0) || user.rate">{{ user.rate }}р.</span>
                <span v-else>Не указан</span>
              </td>
              <td style="min-width: 110px;" class="text-right">
                <v-btn v-if="user.uid != '7FwbL1TfxtVX9TlEsJZfJyuXvg13'" @click.stop="editUser(user)" icon color="#424242">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="user.uid != '7FwbL1TfxtVX9TlEsJZfJyuXvg13'" @click.stop="deleteUser(user)" icon color="#bf4037">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import UserModal from  '@/components/Modals/User/Index'
import Confirm from  '@/components/Modals/Confirm'
import { RoleText } from '@/helpers'

export default {
  name: 'Users',
  components: {},
  data: () => ({
    roleText: RoleText,
    error: null,
    openFilters: false
  }),
  async created() {
    // if (this.users.length === 0) {
    //   this.$store.dispatch('users/loadUsers')
    // }
    // Надо вызывать при каждом заходе что бы обновилась инфа о бригаде
    this.$store.dispatch('users/loadUsers')
  },
  methods: {
    goUser(user) {
      this.$router.push({ name: 'User', params: { uid: user.uid } })
    },
    async editUser(user) {
      this.$showModal({
        component: UserModal,
        isPersistent: true,
        props: {
          edit: true,
          data: user
        }
      })
    },
    async createUser() {
      this.$showModal({
        component: UserModal,
        isPersistent: true
      })
    },
    async deleteUser(user) {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление профиля`,
          text: `Действительно хотите удалить профиль "${user.displayName}"?`
        }
      })

      if (res) {
        this.$store.dispatch('users/deleteUser', user)
      }
    }
  },
  computed: {
    filterUserByRole: {
      get() { return this.$store.getters['users/getFilterUserByRole'] },
      set(val) { this.$store.commit('users/setFilterUserByRole', val) }
    },
    searchUsers: {
      get() { return this.$store.getters['users/getSearchUsers'] },
      set(val) { this.$store.commit('users/setSearchUsers', val) }
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    userRoles() {
      const arrUserRoles = []
      for (let key in this.roleText) {
        arrUserRoles.push({ value: key, text: this.roleText[key] })
      }
      return arrUserRoles
    }
  }
}
</script>
